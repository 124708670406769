import { createStore } from 'vuex';
import { auth, database } from '@/firebase';
import { doc, onSnapshot } from 'firebase/firestore';

export default createStore({
	state: {
		username: null,
		color: null,
		storageUsed: null,
		viewMode: null,
		friends: null,
		blocked: null,
	},
	mutations: {
		SET_USER_DATA(state, data) {
			state.storageUsed = data.storage;
			state.viewMode = data.view;
			state.friends = data.friends;
			state.blocked = data.blocked;
			state.username = data.username;
			state.color = data.color;
		}
	},
	actions: {
		fetchUserData({ commit }) {
			if (auth.currentUser.emailVerified) {
				onSnapshot(doc(database, 'users', auth.currentUser.uid), (doc) => {
					commit('SET_USER_DATA', doc.data());
				});
			}
		},
	},
});