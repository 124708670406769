import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
	apiKey: "AIzaSyB3AimszEkJI1WYtnghwMnxHhgAv2z-E9Q",
	authDomain: "zigro-system.firebaseapp.com",
	projectId: "zigro-system",
	storageBucket: "zigro-system.appspot.com",
	messagingSenderId: "1003178915952",
	appId: "1:1003178915952:web:07dbfe4b37a7cf81c61cb5",
	measurementId: "G-P9Q7CWZBR8",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const database = getFirestore(app);
const analytics = getAnalytics(app);
const storage = getStorage(app);

export { auth, database, analytics, storage };