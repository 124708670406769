import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import {
    faStar as faStarSolid,
    faUser as faUserSolid,
    faSignIn,
    faSignOut,
    faPlus,
    faMagnifyingGlass,
    faLink,
    faArrowUpFromBracket,
    faCaretUp,
    faXmark,
    faEllipsis,
    faShareNodes,
    faLock,
    faRotate,
    faBan,
    faUsers,
    faUserSlash,
} from "@fortawesome/free-solid-svg-icons";

import {
    faUser as faUserRegular,
    faStar as faStarRegular,
    faEdit,
    faTrashCan,
    faFloppyDisk,
    faCircleCheck,
    faCircleXmark,
    faFile,
    faCircleQuestion,
} from '@fortawesome/free-regular-svg-icons';

import {
    faGoogle,
    faFacebook,
} from '@fortawesome/free-brands-svg-icons';

library.add(
    faSignIn,
    faSignOut,
    faPlus,
    faMagnifyingGlass,
    faLink,
    faArrowUpFromBracket,
    faCaretUp,
    faXmark,
    faEllipsis,
    faStarSolid,
    faShareNodes,
    faUserRegular,
    faUserSolid,
    faEdit,
    faTrashCan,
    faFloppyDisk,
    faCircleCheck,
    faFile,
    faCircleXmark,
    faStarRegular,
    faLock,
    faGoogle,
    faFacebook,
    faCircleQuestion,
    faRotate,
    faBan,
    faUsers,
    faUserSlash,
);

export default FontAwesomeIcon;