import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router.js';
import store from '@/store.js';
import icons from '@/icons.js';

const app = createApp(App);

app.config.globalProperties.VERSION = 'Beta v3.5';
app.config.globalProperties.MAX_USER_STORAGE = 524288000;

app
    .use(store)
    .use(router)
    .component("font-awesome-icon", icons)
    .mount('#app');