<template>
	<router-view />
</template>

<script>
import '@/styles/main.scss'
import { useStore } from 'vuex';
import { auth } from '@/firebase';

export default {
	methods: {
		intialize: function () {
			const store = useStore();

			auth.onAuthStateChanged((user) => {
				if (user) {
					store.dispatch('fetchUserData');
				}
			});
		},
	},
	created() {
		this.intialize();
	},
}
</script>