import { createRouter, createWebHistory } from 'vue-router';
import { auth } from '@/firebase';
import { onAuthStateChanged } from 'firebase/auth';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Zigro System',
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
    meta: {
      title: 'Zaloguj się | Zigro System',
    }
  },
  {
    path: '/reset',
    name: 'reset',
    component: () => import('@/views/ResetView.vue'),
    meta: {
      title: 'Zmień hasło | Zigro System',
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/RegistrationView.vue'),
    meta: {
      title: 'Zarejestruj się | Zigro System',
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('@/views/ErrorView.vue'),
    meta: {
      title: 'Błąd 404',
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const removeListener = onAuthStateChanged(
      auth,
      (user) => {
        removeListener();
        resolve(user);
      },
      reject
    );
  });
};

router.beforeEach(async (to) => {
  const user = await getCurrentUser();

  document.title = to.meta.title;

  if ((to.path == '/login' || to.path == '/register') && user) {
    return { name: 'home' };
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (user) {
      return;
    } else {
      return { name: 'login' };
    }
  }
});

export default router;